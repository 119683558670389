
<div class="row filter-container mt-3">
  <div class="col-md-12">
    <span class="fiter-tag active"
      >Fully Compliant Items
      <img
        src="assets/images/icons/icon-close.svg"
        class="ms-2 cursor-pointer"
        width="15"
    /></span>
  </div>
</div>


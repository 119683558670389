import { Component } from '@angular/core';

@Component({
  selector: 'app-filter-tag',
  standalone: true,
  imports: [],
  templateUrl: './filter-tag.component.html',
  styleUrl: './filter-tag.component.scss'
})
export class FilterTagComponent {

}
